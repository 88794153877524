var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "card",
      class: { disabled: _vm.disabled },
      style: {
        backgroundColor: _vm.cardBgColor,
        borderColor: _vm.color,
        cursor: _vm.active && !_vm.disabled ? "auto" : "pointer"
      }
    },
    [
      _c(
        "span",
        {
          directives: [
            {
              name: "autosize",
              rawName: "v-autosize",
              value: 30,
              expression: "30"
            }
          ],
          staticClass: "card-count",
          style: { backgroundColor: _vm.countBgColor, color: _vm.countColor }
        },
        [_vm._v(_vm._s(_vm.title != "expired Pickls" ? _vm.count : ""))]
      ),
      _c(
        "div",
        {
          directives: [
            {
              name: "autosize",
              rawName: "v-autosize",
              value: 19,
              expression: "19"
            }
          ],
          staticClass: "card-content",
          style: { color: _vm.titleColor }
        },
        [_c("span", [_vm._v(_vm._s(_vm.title))])]
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }