









import Vue from 'vue'
import { Prop, Component } from 'vue-property-decorator'

@Component
export default class HollowCard extends Vue {
    @Prop({ default: 0 }) count!: number;
    @Prop({ default: '' }) title!: string;
    @Prop({ default: '' }) color!: string;
    @Prop({ default: false }) active!: boolean;
    @Prop({ default: false }) disabled!: boolean;

    get cardBgColor (): string {
      return this.active ? this.color : 'white'
    }

    get countBgColor (): string {
      return this.active ? 'white' : this.color
    }

    get countColor (): string {
      return this.active ? this.color : 'white'
    }

    get titleColor (): string {
      return this.active ? 'white' : 'rgba(0,0,0,0.8)'
    }
}
